"use client";

import classNames from "classnames";
import { type StoryItem } from "@/lib/streamDataUtils";
import {
  type StoryCardLayout,
  type StoryCardStyles,
  type StoryCardTitlePosition,
} from "../storyCard/StoryCard";
import { StoryCarousel, type StoryCarouselConfig } from "./StoryCarousel";
import { StoryGrid } from "./StoryGrid";

export type StoriesModuleVariant = "carousel" | "grid" | "grid-compact";

export type StoryStylesFunction = (
  storyItem: StoryItem,
  index: number,
) => StoryCardStyles;

export type StoryConfig = {
  layout?: StoryCardLayout;
  titlePosition?: StoryCardTitlePosition;
  getStoryStyles?: StoryStylesFunction;
};

export type StoriesModuleProps = {
  carouselConfig?: StoryCarouselConfig;
  className?: string;
  storyItems: StoryItem[];
  storyConfig?: StoryConfig;
  subtitle?: string;
  title: string;
  variant: StoriesModuleVariant;
};

export const StoriesModule: React.FC<StoriesModuleProps> = ({
  carouselConfig,
  className,
  storyItems,
  storyConfig,
  subtitle,
  title,
  variant,
}) => {
  return (
    <div
      className={classNames(
        "m-auto max-w-uh-custom",
        {
          "rounded-xl": variant === "carousel",
        },
        className,
      )}
    >
      {variant === "carousel" ? (
        // Carousel Variant
        <StoryCarousel
          config={carouselConfig}
          title={title}
          subtitle={subtitle}
          storyItems={storyItems}
          storyConfig={storyConfig}
        />
      ) : (
        // Grid Variant
        <StoryGrid
          title={title}
          subtitle={subtitle}
          storyItems={storyItems}
          storyConfig={storyConfig}
          variant={variant === "grid-compact" ? "compact" : "standard"}
        />
      )}
    </div>
  );
};
