import { useMemo } from "react";
import { type StoryItem } from "@/lib/streamDataUtils";
import Carousel, { type CarouselProps } from "../carousel/Carousel";
import { StoryCard } from "../storyCard/StoryCard";
import { type StoryConfig } from "./StoriesModule";

export type StoryCarouselConfig = Pick<
  CarouselProps,
  "fullBleedSize" | "navStyles" | "slideGapSize"
>;

type Props = {
  className?: string;
  config?: StoryCarouselConfig;
  storyItems: StoryItem[];
  storyConfig?: StoryConfig;
  subtitle?: string;
  title: string;
};

export const StoryCarousel: React.FC<Props> = ({
  className,
  config,
  storyItems,
  storyConfig,
  subtitle,
  title,
}: Props) => {
  const storyCards = useMemo(
    () =>
      storyItems.map((storyItem, index) => (
        <StoryCard
          key={storyItem.id}
          layout={storyConfig?.layout || "standard"}
          storyItem={storyItem}
          styles={storyConfig?.getStoryStyles?.(storyItem, index)}
          titlePosition={storyConfig?.titlePosition || "bottom"}
        />
      )),
    [storyItems, storyConfig],
  );

  return (
    <Carousel
      className={className}
      title={title}
      subtitle={subtitle}
      items={storyCards}
      navStyles={{
        disabled: "border border-dirty-seagull bg-grey-hair",
        enabled:
          "bg-white border-dirty-seagull text-creator-purple hover:!bg-creator-purple hover:text-white",
      }}
      {...config}
    />
  );
};
