"use client";

import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import React, { type ReactNode, useId } from "react";
import ErrorBoundary from "@/components/common/ErrorBoundary";
import { SectionTitle } from "../sectionTitle/SectionTitle";
import { CarouselNavControls, type NavStyles } from "./CarouselNavControls";

export type CarouselProps = {
  className?: string;
  /**
   * The amount cards are allowed to overflow on the right edge of the carousel.
   * Can use any standard CSS unit, e.g. 1rem or 16px.
   *
   * If the X padding around the Carousel is reactive,
   * match this to the largest padding when you want the full bleed effect.
   */
  fullBleedSize?: string;
  items: ReactNode[];
  navStyles?: NavStyles;
  slideGapSize?: string;
  subtitle?: string;
  title?: string;
  titleStyles?: string;
};

export const Carousel: React.FC<CarouselProps> = ({
  className,
  fullBleedSize,
  items,
  navStyles,
  slideGapSize = "1rem",
  subtitle,
  title,
  titleStyles,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    // Align slides to start
    align: "start",
    // Group slides automatically
    slidesToScroll: "auto",
  });

  return (
    <ErrorBoundary id={useId()}>
      <section
        aria-roledescription="carousel"
        className={classNames("flex flex-col gap-2", className)}
        style={
          {
            "--full-bleed-size": fullBleedSize,
            "--slide-gap-size": slideGapSize,
          } as React.CSSProperties
        }
      >
        {/* Header */}
        <div
          className={classNames(
            "flex items-center",
            title ? "justify-between" : "justify-end",
          )}
        >
          {/* Title */}
          {!!title && (
            <SectionTitle
              title={title}
              titleClassName={titleStyles}
              subtitle={subtitle}
            />
          )}

          {/* Navigation Controls */}
          <CarouselNavControls
            displaySlideCount
            emblaApi={emblaApi}
            navStyles={navStyles}
          />
        </div>

        {/* Carousel Viewport */}
        <div
          className={classNames("overflow-hidden", {
            "mx-[calc(var(--full-bleed-size)_*_-1)]": fullBleedSize,
          })}
          ref={emblaRef}
        >
          {/* Container */}
          <div
            className={classNames(
              "flex touch-pan-y touch-pinch-zoom",
              fullBleedSize
                ? "ml-[calc(var(--full-bleed-size)_-_var(--slide-gap-size))] mr-[--full-bleed-size]"
                : "ml-[calc(var(--slide-gap-size)_*_-1)]",
            )}
          >
            {/* Slide */}
            {items.map((item) => (
              <div className="min-w-0 shrink-0 grow-0 basis-[calc(100%_-_3rem)] transform-gpu pl-[--slide-gap-size] sm:basis-[calc(50%_-_2rem)] lg:basis-[calc(33%_-_1rem)] 2xl:basis-[calc(25%_-_.5rem)]">
                {item}
              </div>
            ))}
          </div>
        </div>
      </section>
    </ErrorBoundary>
  );
};

export default Carousel;
