import classNames from "classnames";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { ArticleLink } from "@/components/common/ArticleLink";
import { NCPImage } from "@/components/shared/NCPImage/index";
import { type StreamImage } from "@/components/shared/Stream/data";
import { COMMON_NTK_LINK_I13N_PARAMS } from "@/lib/i13n";
import { I13nStartIndexContext } from "@/lib/I13nStartIndexContext";
import { type StoryItem } from "@/lib/streamDataUtils";

export type StoryCardLayout = "standard" | "fullbleed";
export type StoryCardTitlePosition = "top" | "bottom";
export type StoryCardStyles = {
  authorText?: string;
  authorImage?: string;
  container?: string;
  headerContainer?: string;
  title?: string;
};

export const StoryCard = ({
  className,
  layout = "standard",
  storyItem,
  styles,
  titlePosition = "top",
}: {
  className?: string;
  layout?: StoryCardLayout;
  storyItem: StoryItem;
  styles?: StoryCardStyles;
  titlePosition?: StoryCardTitlePosition;
}) => {
  const {
    attribution,
    bypassModal,
    id,
    instrumentation,
    thumbnail,
    title,
    url,
  } = storyItem;
  const i13nStartIndex = useContext(I13nStartIndexContext);

  const i13nIndex = i13nStartIndex + 1;

  return (
    <article
      className={classNames(
        "group/follow relative flex size-full",
        titlePosition === "bottom" ? "flex-col-reverse" : "flex-col",
        { "overflow-hidden rounded-lg bg-white": layout === "standard" },
        styles?.container,
        className,
      )}
    >
      {/* Text Content */}
      <header
        className={classNames(
          "flex min-w-0 flex-1 flex-col gap-1.5 pb-4 pt-3",
          {
            "justify-between": layout === "standard" && titlePosition === "top",
            "px-0": layout === "fullbleed",
            "px-3": layout === "standard",
          },
          styles?.headerContainer,
        )}
      >
        {/* Title */}
        <ArticleLink
          className={classNames(
            "stretched-box headline-s md:headline-m line-clamp-3 text-ellipsis font-bold group-hover/follow:underline",
            styles?.title,
          )}
          dataYlk={{
            cpos: 2,
            g: id,
            mab_test: instrumentation?.mab,
            mpos: 2,
            pos: i13nIndex,
            ss_cid: id,
            ...COMMON_NTK_LINK_I13N_PARAMS,
          }}
          disableModal={bypassModal}
          href={url}
        >
          {title}
        </ArticleLink>

        {/* Attribution */}
        <Attribution
          image={attribution.image}
          source={attribution.source}
          classNameImage={styles?.authorImage}
          classNameSource={styles?.authorText}
        />
      </header>

      {/* Thumbnail */}
      <NCPImage
        alt=""
        className={classNames("w-full", {
          "rounded-lg": layout === "fullbleed",
        })}
        image={thumbnail}
        size="228x128|2|95"
        priority
      />
    </article>
  );
};

function Attribution({
  image,
  source,
  classNameImage,
  classNameSource,
}: {
  image?: StreamImage | null;
  source: string;
  classNameImage?: string;
  classNameSource?: string;
}) {
  const intl = useIntl();

  return (
    <div className="flex flex-row items-center gap-1.5 truncate">
      <NCPImage
        alt={intl.formatMessage(
          { id: "creators.PROFILE_IMAGE" },
          { brandName: source },
        )}
        className={classNames("size-5 rounded-full", classNameImage)}
        image={image}
        size="80x80"
        priority
      />
      <span
        className={classNames(
          "line-clamp-1 text-ellipsis text-wrap text-sm text-battleship dark:text-bob",
          classNameSource,
        )}
      >
        {source}
      </span>
    </div>
  );
}
