import classNames from "classnames";
import { type StoryItem } from "@/lib/streamDataUtils";
import { SectionTitle } from "../sectionTitle/SectionTitle";
import { StoryCard } from "../storyCard/StoryCard";
import { type StoryConfig } from "./StoriesModule";

type Props = {
  className?: string;
  storyItems: StoryItem[];
  storyConfig?: StoryConfig;
  subtitle?: string;
  title: string;
  /**
   * Standard variant has 1 column for screen sizes < sm.
   * Compact variant has 2 columns for screen sizes < sm.
   */
  variant?: "standard" | "compact";
};

/**
 * This component is designed to render 3, 4, 6, or 8 stories.
 * Any other number is not explicitly supported, though it should still work.
 */
export const StoryGrid: React.FC<Props> = ({
  className,
  storyItems,
  storyConfig,
  subtitle,
  title,
  variant = "standard",
}: Props) => {
  // If there aren't enough stories to show, don't render the grid.
  if (storyItems.length < 3) {
    return null;
  }

  // If the story count is divisible by 3, use 3 columns for large screens.
  // Otherwise, 4 columns will be used.
  const threeColumns = storyItems.length % 3 === 0;

  return (
    <section className={classNames("flex flex-col gap-4 py-3", className)}>
      {/* Header */}
      <SectionTitle title={title} subtitle={subtitle} />

      {/* Content Grid */}
      <div
        className={classNames("grid", {
          "grid-cols-1 gap-4 sm:grid-cols-2": variant === "standard",
          "grid-cols-2 gap-3 sm:gap-4": variant === "compact",
          "md:grid-cols-3": threeColumns,
          "md:grid-cols-4": !threeColumns,
        })}
      >
        {storyItems.map((storyItem: StoryItem, index: number) => (
          <StoryCard
            className={classNames({
              // For the first story, if:
              // 1) the number of stories is odd and
              // 2) we are in the sm-md breakpoint range, then
              // the story should span both columns.
              "col-span-2 md:col-span-1":
                variant === "compact" &&
                index === 0 &&
                storyItems.length % 2 === 1,
            })}
            key={storyItem.id}
            layout={storyConfig?.layout || "fullbleed"}
            storyItem={storyItem}
            styles={storyConfig?.getStoryStyles?.(storyItem, index)}
            titlePosition={storyConfig?.titlePosition || "bottom"}
          />
        ))}
      </div>
    </section>
  );
};
