import classNames from "classnames";

export function SectionTitle({
  className,
  title,
  titleClassName,
  subtitle,
}: {
  className?: string;
  title: string;
  titleClassName?: string;
  subtitle?: string;
}) {
  return (
    <div className={classNames("flex flex-col", className)}>
      {/* Title */}
      <h2
        className={classNames("font-centra text-2xl font-bold", titleClassName)}
      >
        {title}
      </h2>
      {/* Subtitle */}
      {!!subtitle && (
        <span className="line-clamp-1 text-ellipsis text-sm font-[450]">
          {subtitle}
        </span>
      )}
    </div>
  );
}
