"use client";

import { getDataYlkString, type I13NSec, useRapid } from "@yahoo-creators/i13n";

import { useIntl } from "react-intl";

import { type StoryItem } from "@/lib/streamDataUtils";
import {
  StoriesModule,
  type StoryConfig,
} from "../storiesModule/StoriesModule";

type TopTableProps = {
  trending: StoryItem[];
};

const TOP_STORIES_SEC: I13NSec = "y4c-trending-stories";
const TOP_STORIES_SUB_SEC: I13NSec = "creator-hub";

const stylesByPosition: {
  [key: number]: { container: string; text: string };
} = {
  0: { container: "!bg-batcave", text: "!text-white" },
  1: { container: "!bg-creator-yellow", text: "!text-inkwell" },
  2: { container: "!bg-creator-purple", text: "!text-white" },
};

const storyConfig: StoryConfig = {
  getStoryStyles: (_storyItem, index) => {
    const { container, text } = stylesByPosition[index % 3];
    return {
      authorText: text,
      container,
      title: text,
    };
  },
  titlePosition: "top",
};

export const TrendingStories = ({ trending }: TopTableProps) => {
  useRapid(TOP_STORIES_SEC);
  const intl = useIntl();

  return (
    <section
      id={TOP_STORIES_SEC}
      data-ylk={getDataYlkString({
        sec: TOP_STORIES_SEC,
        subsec: TOP_STORIES_SUB_SEC,
      })}
    >
      <StoriesModule
        className="p-4 md:p-6 lg:px-8"
        title={intl.formatMessage({ id: "creators.TRENDING_STORIES_TITLE" })}
        storyItems={trending}
        storyConfig={storyConfig}
        variant="carousel"
      />
    </section>
  );
};
