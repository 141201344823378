import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type EmblaCarouselType } from "embla-carousel";
import React, {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import NextArrow from "@/svgs/nextArrow.svg";
import PrevArrow from "@/svgs/prevArrow.svg";

export interface NavStyles {
  button?: string;
  container?: string;
  disabled?: string;
  enabled?: string;
}

export const CarouselNavControls = ({
  displaySlideCount,
  emblaApi,
  navStyles,
}: {
  displaySlideCount?: boolean;
  emblaApi: EmblaCarouselType | undefined;
  navStyles?: NavStyles;
}) => {
  const { formatMessage } = useIntl();

  const { selectedSnap, snapCount } = useSelectedSnapDisplay(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div
      className={classNames(
        "hidden items-center gap-2 sm:flex",
        navStyles?.container,
      )}
      aria-label={formatMessage({ id: "article.SLIDE_CONTROLS" })}
      role="group"
    >
      {displaySlideCount && (
        <div className="mr-1 text-sm">
          {formatMessage(
            { id: "article.CAROUSEL_SLIDE_COUNT" },
            { index: selectedSnap + 1, total: snapCount },
          )}
        </div>
      )}
      <NavButton
        direction="prev"
        disabled={prevBtnDisabled}
        label={formatMessage({ id: "article.PREV_SLIDE" })}
        navStyles={navStyles}
        onClick={onPrevButtonClick}
      >
        <PrevArrow className="fill-current" height={12} width={12} />
      </NavButton>
      <NavButton
        direction="next"
        disabled={nextBtnDisabled}
        label={formatMessage({ id: "article.NEXT_SLIDE" })}
        navStyles={navStyles}
        onClick={onNextButtonClick}
      >
        <NextArrow className="fill-current" height={12} width={12} />
      </NavButton>
    </div>
  );
};

type UseSelectedSnapDisplayType = {
  selectedSnap: number;
  snapCount: number;
};

export const useSelectedSnapDisplay = (
  emblaApi: EmblaCarouselType | undefined,
): UseSelectedSnapDisplayType => {
  const [selectedSnap, setSelectedSnap] = useState(0);
  const [snapCount, setSnapCount] = useState(0);

  const updateScrollSnapState = useCallback((emblaApi: EmblaCarouselType) => {
    setSnapCount(emblaApi.scrollSnapList().length);
    setSelectedSnap(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    updateScrollSnapState(emblaApi);
    emblaApi.on("select", updateScrollSnapState);
    emblaApi.on("reInit", updateScrollSnapState);
  }, [emblaApi, updateScrollSnapState]);

  return {
    selectedSnap,
    snapCount,
  };
};

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

function usePrevNextButtons(
  emblaApi: EmblaCarouselType | undefined,
): UsePrevNextButtonsType {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    nextBtnDisabled,
    onNextButtonClick,
    onPrevButtonClick,
    prevBtnDisabled,
  };
}

type ButtonProps = PropsWithChildren<{
  direction: "prev" | "next";
  disabled: boolean;
  label: string;
  navStyles?: NavStyles;
  onClick: () => void;
}>;

function NavButton({
  navStyles,
  direction,
  label,
  disabled,
  onClick,
  children,
}: ButtonProps) {
  return (
    <I13nButton
      aria-label={label}
      className={classNames(
        classNames(
          "flex size-10 items-center justify-center rounded-full border transition-colors",
          navStyles?.button,
        ),
        disabled
          ? classNames(
              "cursor-not-allowed bg-dirty-seagull text-pebble",
              navStyles?.disabled,
            )
          : classNames(
              "bg-grey-hair text-brand-purple hover:bg-pebble",
              navStyles?.enabled,
            ),
      )}
      disabled={disabled}
      aria-disabled={disabled}
      onClick={onClick}
      dataYlk={{
        cpos: 1,
        elm: "arrow",
        itc: 1,
        mpos: 1,
        pos: 1,
        pt: "",
        slk: direction,
      }}
    >
      {children}
    </I13nButton>
  );
}
