"use client";

import {
  getDataYlkString,
  I13nAnchor,
  type I13NSec,
  useRapid,
} from "@yahoo-creators/i13n";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CREATOR_HUB_SUBSEC } from "@/app/creators/config";
import { Carousel } from "@/components/article/common/Carousel";
import { carouselStyles } from "@/components/article/common/CarouselNavControls";
import { NCPImage } from "@/components/shared/NCPImage";
import {
  type StreamImage,
  type StreamItem,
} from "@/components/shared/Stream/data";
import { sanitizeStreamItem, type StoryItem } from "@/lib/streamDataUtils";
import { brandUrlToRelativePath } from "../../utils";
import { StoryCard } from "../storyCard/StoryCard";

const I13N_SEC: I13NSec = "creator-spotlight";
const BACKGROUND_PARALLAX_RANGE = 250; // px

type FeaturedCreator = {
  creator: {
    id?: string;
    image?: StreamImage | null;
    name?: string;
    description?: string;
    url?: string;
  };
  story: StoryItem;
};

export const CreatorSpotlight = ({ data }: { data: StreamItem[] }) => {
  const { formatMessage } = useIntl();
  useRapid(I13N_SEC);

  // Add a background parallax effect
  const [backgroundOffset, setBackgroundOffset] = useState(
    BACKGROUND_PARALLAX_RANGE * 0.5,
  );
  const handleScrollChange = useCallback(
    (clientWidth: number, scrollWidth: number, scrollLeft: number) => {
      const scrollRange = scrollWidth - clientWidth;
      const scrollPercentage = scrollLeft / scrollRange;
      setBackgroundOffset(
        Math.round(BACKGROUND_PARALLAX_RANGE * (0.5 - scrollPercentage)),
      );
    },
    [],
  );

  const slides = useMemo(
    () =>
      data
        .map((item: StreamItem): FeaturedCreator | null => {
          const providerBrand = item?.content?.provider?.providerBrand;
          if (!providerBrand) {
            return null;
          }
          return {
            creator: {
              description: providerBrand.description,
              id: providerBrand.id,
              image: providerBrand.imageByType,
              name: providerBrand.displayName,
              // Use a relative URL so the link works in all environments
              url: brandUrlToRelativePath(providerBrand?.brandUrl?.url),
            },
            story: sanitizeStreamItem(item),
          };
        })
        .filter((creator) => creator != null)
        .map(({ story, creator }, index) => {
          const i13nCommonParams = {
            ct: "creator",
            g: creator.id,
            mpos: 1,
            pt: "",
            sec: I13N_SEC,
            subsec: CREATOR_HUB_SUBSEC,
          };

          return (
            <div
              key={story.id}
              className="mx-auto flex w-full shrink-0 flex-col gap-8 md:flex-row"
            >
              {/* Brand Info */}
              <div className="flex grow flex-col">
                {/* Brand image */}
                <I13nAnchor
                  className="relative size-max rounded-full transition-colors after:absolute after:left-0 after:top-0 after:size-full after:rounded-full after:border-brand-purple after:opacity-0 after:transition-opacity after:content-[''] hover:after:border-4 hover:after:bg-[rgba(0,0,0,0.1)] hover:after:opacity-100 active:after:opacity-100 hover:active:after:bg-[rgba(0,0,0,0.2)]"
                  dataYlk={{
                    ...i13nCommonParams,
                    elm: "img",
                    pos: index + 1,
                    slk: creator.name,
                  }}
                  href={creator.url}
                  target="_self"
                >
                  <NCPImage
                    alt={
                      creator.image?.altText ||
                      formatMessage(
                        { id: "creators.PROFILE_IMAGE" },
                        { brandName: creator.name },
                      )
                    }
                    className="size-[160px] self-center rounded-full sm:self-start md:size-[200px]"
                    image={creator.image}
                    priority
                  />
                </I13nAnchor>

                {/* Brand name */}
                <I13nAnchor
                  className="mt-4 line-clamp-3 w-fit max-w-full text-ellipsis font-centra text-[28px] font-bold uppercase leading-none tracking-tight text-brand-purple hover:underline md:-mb-1 md:pb-1 md:text-[40px]"
                  dataYlk={{
                    ...i13nCommonParams,
                    cpos: index + 1,
                    elm: "hdln",
                    pos: index + 1,
                    slk: creator.name,
                  }}
                  href={creator.url}
                  target="_self"
                >
                  {creator.name}
                </I13nAnchor>

                {/* Brand description */}
                <span className="mt-2 line-clamp-3 max-w-full text-[18px] tracking-tight text-inkwell xl:line-clamp-2">
                  {creator.description}
                </span>

                {/* Creators page link */}
                <I13nAnchor
                  className="mt-2 flex size-max items-center justify-center rounded-3xl border-2 border-brand-purple px-7 py-2 text-brand-purple transition-colors hover:bg-brand-purple hover:text-white active:hover:bg-[#6b19be] md:mb-0 md:mt-4"
                  dataYlk={{
                    ...i13nCommonParams,
                    cpos: index + 1,
                    elm: "img",
                    p_sys: "",
                    pos: index + 1,
                    slk: "explore",
                  }}
                  href={creator.url}
                  target="_self"
                  aria-label={`Explore more about ${creator.name}`}
                >
                  <span className="text-sm font-medium leading-none">
                    {formatMessage({ id: "creators.EXPLORE" })}
                  </span>
                </I13nAnchor>
              </div>

              {/* Right side */}
              <div className="shrink-0 md:mt-auto md:w-[360px] lg:w-[460px] xl:w-[600px]">
                <StoryCard
                  className="!rounded-2xl shadow-[0_-2px_8px_0_rgba(0,0,0,0.05)] transition-shadow active:shadow-[0_-2px_4px_0_rgba(0,0,0,0.2)]"
                  storyItem={story}
                  styles={{
                    authorText: "text-sm text-battleship font-[450]",
                    headerContainer: "!gap-2",
                    title: "!text-lg md:!text-xl !leading-none",
                  }}
                />
              </div>
            </div>
          );
        }),
    [data, formatMessage],
  );

  return (
    <section
      className="bg-[url('https://s.yimg.com/cv/apiv2/creators/discovery-hub/bg_mobile.jpg')] bg-[auto_844px] bg-no-repeat md:bg-[url('https://s.yimg.com/cv/apiv2/creators/discovery-hub/bg_desktop.jpg')] md:bg-[auto_600px]"
      style={{
        backgroundPosition: `calc(50% + ${backgroundOffset}px) 0`,
      }}
      id={I13N_SEC}
      data-ylk={getDataYlkString({
        sec: I13N_SEC,
        subsec: CREATOR_HUB_SUBSEC,
      })}
    >
      <div className="mx-auto mb-2 max-w-uh-custom py-6 md:mb-4 xl:mb-0">
        <Carousel
          displaySlideCount
          title={formatMessage({ id: "creators.SPOTLIGHT_TITLE" })}
          titleStyles="!text-white !mb-9 px-4 !md:mb-2 md:px-6 lg:px-8 font-bold !text-2xl max-w-[345px] sm:max-w-none"
          slides={slides}
          carouselStyles="flex items-start md:items-center"
          slideStyles="w-full shrink-0 p-4 md:px-6 md:py-8 lg:p-8"
          navStyles={{
            ...carouselStyles.pillStyles,
            button:
              "flex items-center justify-center p-2 rounded-full border border-white size-10 transition-colors text-inkwell",
            container:
              "absolute right-4 md:right-6 lg:right-8 top-[5px] md:top-[-5px] z-[1] gap-2.5 flex",
            disabled: "bg-[rgba(255,255,255,0.3)] cursor-not-allowed",
            enabled:
              "bg-white hover:text-brand-purple hover:border-brand-purple active:border-2 active:border-brand-purple",
            next: classNames(carouselStyles.pillStyles.next, "hidden md:flex"),
            prev: classNames(carouselStyles.pillStyles.prev, "hidden md:flex"),
            slideCount:
              "self-center text-sm text-white rounded-full px-2 pb-[2px] bg-[#491480b2]/[0.7]",
          }}
          navIndicatorStyles={{
            active: "bg-white md:bg-brand-purple",
            always:
              "rounded-full size-4 border border-white md:border-brand-purple transition-colors md:hover:bg-brand-purple",
            button: "p-2.5 md:p-1",
            container:
              "flex justify-center gap-0 md:gap-1 absolute top-16 sm:top-8 left-2 md:static",
            inactive: "bg-transparent",
          }}
          onScrollChange={handleScrollChange}
        />
      </div>
    </section>
  );
};
